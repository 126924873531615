// 短链的
import request from '@/network/request.js'
/**
 * 获取短链的列表
 */
export function lists_short_url(data) {
	return request({
		url: '/admin/confe_short_url/lists_short_url',
		method: 'post',
		data,
	})
}
/**
 * 更新和编辑短链
 */
export function creat_up_short_url(data) {
	return request({
		url: '/admin/confe_short_url/creat_up_short_url',
		method: 'post',
		data,
	})
}


/**
 * 删除|批量删除短链
 */
export function dels_short_url(data) {
	return request({
		url: '/admin/confe_short_url/dels_short_url',
		method: 'post',
		data,
	})
}
